.card-title a:hover,
.card-title a {
  color: inherit;
}

.product-image,
.images img,
.carousel-item img {
  height: 320px;
  margin: 1em auto;
  display: block;
}

.product-icon {
  width: 128px;
  height: 128px;
  float: left;
  margin-right: 0.5em;
}

.buttons .btn-toolbar {
  justify-content: center;
}

.buttons .btn,
.product-button {
  margin: 0.5em;
}

.product-card.card {
  min-width: 240px;
  max-width: 320px;
  margin: 0.5rem;
}

.product-card .product-button {
  float: right;
  margin-right: 0;
  margin-bottom: 1em;
}
